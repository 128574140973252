import { Text } from "@pancakeswap/uikit";
import styled from "styled-components";

export interface IYourPoints {
  userPoints: number;
}

const YourPointsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const YourPoints: React.FunctionComponent<React.PropsWithChildren<IYourPoints>> = ({ userPoints }) => {
  return (
    <YourPointsContainer>
      <Text fontWeight={500} color={userPoints === 0 ? "textDisabled" : "white"}>
        {Number(userPoints).toLocaleString("en-US", { maximumFractionDigits: 2 })}%
      </Text>
    </YourPointsContainer>
  );
};

export default YourPoints;
