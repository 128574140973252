import { ChainId } from '@pancakeswap/chains'

// const PRICE_API = 'https://alpha.wallet-api.mancakeswap.com/v1/prices/list/'
const PRICE_API = `${process.env.NEXT_PUBLIC_MANCAKE_API_URL}/v1/prices/list/`

const zeroAddress = '0x0000000000000000000000000000000000000000' as const

// duck typing for native currency, token, token info
export type CurrencyParams =
  | {
      chainId: ChainId
      address: `0x${string}`
      isNative?: false
    }
  | {
      chainId: ChainId
      isNative: true
    }

export type CurrencyKey = `${number}:${string}`

export type CurrencyUsdResult = Record<CurrencyKey, number>

export function getCurrencyKey(currencyParams?: CurrencyParams): CurrencyKey | undefined {
  if (!currencyParams) {
    return undefined
  }

  if ('isNative' in currencyParams && currencyParams.isNative === true) {
    return `${currencyParams.chainId}:${zeroAddress}`
  }
  const { chainId, address } = currencyParams
  return `${chainId}:${address.toLowerCase()}`
}

export function getCurrencyListKey(currencyListParams?: CurrencyParams[]): string | undefined {
  if (!currencyListParams) {
    return undefined
  }

  const currencyKeys = currencyListParams.map(getCurrencyKey).filter((key): key is CurrencyKey => !!key)

  const uniqueKeys = [...new Set(currencyKeys)]

  return uniqueKeys.join(',')
}

function getRequestUrl(params?: CurrencyParams | CurrencyParams[]): string | undefined {
  if (!params) {
    return undefined
  }
  const infoList = Array.isArray(params) ? params : [params]
  const key = getCurrencyListKey(infoList)
  if (!key) {
    return undefined
  }
  const encodedKey = encodeURIComponent(key)
  return `${PRICE_API}${encodedKey}`
}

export async function getCurrencyUsdPrice(currencyParams?: CurrencyParams) {
  const prices = await getCurrencyListUsdPrice(currencyParams && [currencyParams])
  const key = getCurrencyKey(currencyParams)
  return (key && prices[key]) ?? 0
}

export async function getCurrencyListUsdPrice(currencyListParams?: CurrencyParams[]): Promise<CurrencyUsdResult> {
  const requestUrl = getRequestUrl(currencyListParams)
  if (!requestUrl || !currencyListParams) {
    throw new Error(`Invalid request for currency prices, request url: ${requestUrl}`)
  }
  // console.log('asdf')
  // return {
  //   '167000:0x85e298721d46bbce8620d8f909afbb91a9bfd1a6': 0.01,
  //   '167000:0x07d83526730c7438048d55a4fc0b850e2aab6f0b': 0.01,
  //   '167000:0xa9d23408b9ba935c230493c40c73824df71a0975': 0.01,
  // }
  const res = await fetch(requestUrl)
  const data: Array<{
    chainId: number
    tokenAddress: string
    price: number
  }> = await res.json()
  const result: CurrencyUsdResult = {}
  data.forEach((item) => {
    result[`${item.chainId}:${item.tokenAddress}`] = item.price
  })
  return result
}
