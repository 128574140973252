import { ChainId } from "@pancakeswap/chains";
import { useHttpLocations } from "@pancakeswap/hooks";
import { Currency } from "@pancakeswap/sdk";
import { TokenLogo } from "@pancakeswap/uikit";
import { useMemo } from "react";
import { styled } from "styled-components";
import { SpaceProps, space } from "styled-system";

import { taikoTokens } from "@pancakeswap/tokens";
import { getCurrencyLogoUrls } from "./utils";

const StyledLogo = styled(TokenLogo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;

  ${space}
`;

const mantleAddressSymbolMapper = {
  [taikoTokens.taiko.address.toLowerCase()]: "usdt",
  [taikoTokens.usdc.address.toLowerCase()]: "taiko",
  [taikoTokens.usdt.address.toLowerCase()]: "usdt",
};

export function CurrencyLogo({
  currency,
  size = "24px",
  style,
  useTrustWalletUrl,
  ...props
}: {
  currency?: Currency & {
    logoURI?: string | undefined;
  };
  size?: string;
  style?: React.CSSProperties;
  useTrustWalletUrl?: boolean;
} & SpaceProps) {
  const uriLocations = useHttpLocations(currency?.logoURI);
  const srcs: string[] = useMemo(() => {
    if (currency?.chainId === ChainId.TAIKO) {
      return [
        `/images/token-image/${
          currency.symbol.toLowerCase() || mantleAddressSymbolMapper[(currency as any)?.address.toLowerCase()]
        }.png`,
      ];
    }
    if (currency?.isNative) return [];

    if (currency?.isToken) {
      const logoUrls = getCurrencyLogoUrls(currency, { useTrustWallet: useTrustWalletUrl });

      if (currency?.logoURI) {
        return [...uriLocations, ...logoUrls];
      }
      return [...logoUrls];
    }
    return [];
  }, [currency, uriLocations, useTrustWalletUrl]);

  // if (currency?.isNative) {
  //   if (currency.chainId === ChainId.BSC) {
  //     return <BinanceIcon width={size} style={style} {...props} />;
  //   }
  //   return (
  //     <StyledLogo
  //       size={size}
  //       srcs={[
  //         currency.chainId === 5000
  //           ? `images/chains/5000.png`
  //           : `https://assets.pancakeswap.finance/web/native/${currency.chainId}.png`,
  //       ]}
  //       width={size}
  //       style={style}
  //       {...props}
  //     />
  //   );
  // }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? "token"} logo`} style={style} {...props} />;
}
