import React from 'react'
import { Box } from '../Box'
import { Image } from '../Image'
import { SpinnerProps } from './types'

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({ size = 228 }) => {
  return (
    <Box width={size} height={size * 0.7778} position="relative">
      <Image width={size} height={size * 0.7778} src="/images/Pending_transaction.png" alt="pancake-3d-spinner" />
    </Box>
  )
}

export default Spinner
