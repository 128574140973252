import { ChainId } from '@pancakeswap/chains'
import uniq from 'lodash/uniq'

export const supportedChainIdV2 = [
  // ChainId.GOERLI,
  // ChainId.BSC,
  // ChainId.BSC_TESTNET,
  // ChainId.ETHEREUM,
  // TODO: Add Mantle Chain
  ChainId.MANTLE,
  ChainId.TAIKO_HEKLA,
  ChainId.TAIKO,
] as const
export const supportedChainIdV3 = [
  // ChainId.GOERLI,
  // ChainId.BSC,
  // ChainId.BSC_TESTNET,
  // ChainId.ETHEREUM,
  // ChainId.ZKSYNC_TESTNET,
  // ChainId.POLYGON_ZKEVM_TESTNET,
  // ChainId.POLYGON_ZKEVM,
  // ChainId.ZKSYNC,
  // ChainId.ARBITRUM_ONE,
  // ChainId.LINEA,
  // ChainId.BASE,
  // ChainId.OPBNB,
  // ChainId.OPBNB_TESTNET,
  // TODO: Add Mantle Chain
  ChainId.MANTLE,
  ChainId.TAIKO_HEKLA,
  ChainId.TAIKO,
] as const
export const supportedChainId = uniq([...supportedChainIdV2, ...supportedChainIdV3])
export const bCakeSupportedChainId = [ChainId.BSC] as const

export const FARM_AUCTION_HOSTING_IN_SECONDS = 691200

export type FarmSupportedChainId = (typeof supportedChainId)[number]

export type FarmV2SupportedChainId = (typeof supportedChainIdV2)[number]

export type FarmV3SupportedChainId = (typeof supportedChainIdV3)[number]

export const masterChefAddresses = {
  [ChainId.BSC_TESTNET]: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
  [ChainId.BSC]: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
  // TODO: Add Mantle Chain
  [ChainId.MANTLE]: '0xd2ad83fE1fD1A849875763Ba08e33c4cBba754Fe',
  [ChainId.TAIKO_HEKLA]: '0x5778ef9d8Aba22b6bF09DB33c5D4c0fa6402A7Cf',
  [ChainId.TAIKO]: '0x01BDB0ee8577fD27A1956c7e9DfC6c19AAFB255a',
} as const

export const masterChefV3Addresses = {
  // [ChainId.ETHEREUM]: '0x556B9306565093C855AEA9AE92A594704c2Cd59e',
  // [ChainId.GOERLI]: '0x864ED564875BdDD6F421e226494a0E7c071C06f8',
  // [ChainId.BSC]: '0x556B9306565093C855AEA9AE92A594704c2Cd59e',
  // [ChainId.BSC_TESTNET]: '0x4c650FB471fe4e0f476fD3437C3411B1122c4e3B',
  // [ChainId.ZKSYNC_TESTNET]: '0x3c6Aa61f72932aD5D7C917737367be32D5509e6f',
  // [ChainId.POLYGON_ZKEVM_TESTNET]: '0xb66b07590B30d4E6E22e45Ddc83B06Bb018A7B44',
  // [ChainId.POLYGON_ZKEVM]: '0xE9c7f3196Ab8C09F6616365E8873DaEb207C0391',
  // [ChainId.ZKSYNC]: '0x4c615E78c5fCA1Ad31e4d66eb0D8688d84307463',
  // [ChainId.ARBITRUM_ONE]: '0x5e09ACf80C0296740eC5d6F643005a4ef8DaA694',
  // [ChainId.LINEA]: '0x22E2f236065B780FA33EC8C4E58b99ebc8B55c57',
  // [ChainId.BASE]: '0xC6A2Db661D5a5690172d8eB0a7DEA2d3008665A3',
  // [ChainId.OPBNB]: '0x05ddEDd07C51739d2aE21F6A9d97a8d69C2C3aaA',
  // [ChainId.OPBNB_TESTNET]: '0x236e713bFF45adb30e25D1c29A887aBCb0Ea7E21',
  // TODO: Add Mantle Chain
  [ChainId.MANTLE]: '0xEE7a658D5F835E2021Bf6B1B6B003A2a94bB6e79',
  [ChainId.TAIKO_HEKLA]: '0x482BaDfA8Ff39f2Bf500e1a8eaEA0D310b37E9e1',
  [ChainId.TAIKO]: '0x2E47e7e2d7A75D1473995441BbcE7888DC035E74',
} as const satisfies Record<FarmV3SupportedChainId, string>

export const nonBSCVaultAddresses = {
  [ChainId.ETHEREUM]: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
  [ChainId.GOERLI]: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
} as const
