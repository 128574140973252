import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="172" height="32" viewBox="0 0 172 32" {...props}>
      <g clip-path="url(#clip0_2569_5438)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M103.926 24.5801C101.558 24.5801 99.6493 23.895 98.2103 22.5247C96.7833 21.1467 96.0688 19.3198 96.0669 17.044V17.0344V9.49829L99.0792 10.862C100.39 9.95453 102.007 9.49829 103.926 9.49829C105.845 9.49829 107.489 9.95222 108.796 10.8597L111.804 9.49829V17.044C111.804 19.3341 111.08 21.1645 109.641 22.5344C108.212 23.9046 106.313 24.5898 103.925 24.5898V24.5801H103.926ZM99.9233 21.0468C100.795 21.8886 102.125 22.319 103.926 22.319C105.727 22.319 107.058 21.8983 107.929 21.0468C108.8 20.1856 109.23 18.8543 109.23 17.044C109.23 15.2337 108.8 13.8928 107.929 13.0413C107.058 12.1802 105.727 11.7494 103.926 11.7494C102.125 11.7494 100.784 12.1802 99.9233 13.0413C99.0718 13.8832 98.6411 15.2237 98.6411 17.044C98.6411 18.8644 99.0718 20.1953 99.9233 21.0468Z"
          fill="white"
        />
        <path
          d="M82.3846 24.5801H79.9575V9.49829H82.3846V15.9382H86.9551L91.3689 9.49829H94.2465L88.9812 16.9464L94.2272 24.5801H91.3496L87.0042 18.2773H82.3846V24.5801Z"
          fill="white"
        />
        <path
          d="M65.3358 24.5801H62.9087V9.49829H65.5219L72.2748 18.3263L74.2321 21.1448H74.4081L74.2908 18.424V9.49829H76.7179V24.5801H74.1048L67.3028 15.7815L65.4138 13.1293H65.2378L65.3258 15.7425V24.5898L65.3354 24.5801H65.3358Z"
          fill="white"
        />
        <path
          d="M46.623 24.5801H43.9219L50.6555 9.49829H54.0808L60.8144 24.5801H58.0642L56.3319 20.6654H48.3654L46.6331 24.5801H46.6234H46.623ZM51.37 13.8928L49.3246 18.483H55.3534L53.308 13.8928L52.4272 11.7494H52.2512L51.3703 13.8928H51.37Z"
          fill="white"
        />
        <path
          d="M33.9691 24.5801H31.542V9.49829H38.9214C40.634 9.49829 41.9946 9.96804 43.0221 10.8979C44.0597 11.8277 44.5784 13.1197 44.5784 14.7736C44.5784 16.4276 44.0597 17.6998 43.0221 18.6494C42.0043 19.5792 40.634 20.049 38.9214 20.049H33.9691V24.5998V24.5801ZM38.6959 11.6907H33.9687V17.8565H38.6959C39.8705 17.8565 40.7317 17.6118 41.2894 17.1324C41.8572 16.653 42.1409 15.8602 42.1409 14.7736C42.1409 13.6871 41.8572 12.8946 41.2894 12.4052C40.7317 11.9258 39.8701 11.6811 38.6959 11.6811V11.6907Z"
          fill="white"
        />
        <path
          d="M16.9297 5.51658C16.7143 5.32088 14.5809 3.29481 14.5026 0.554663C14.5026 0.554663 14.5026 -0.189143 14.0525 0.0459261C13.6121 0.270959 13.3477 0.789731 12.9856 2.11098C12.6236 3.43223 13.8468 4.05869 14.5809 4.83183C14.5809 4.83183 13.0443 4.66547 12.6529 4.70445C12.2615 4.75348 11.9581 4.87082 11.3317 5.99637C11.3317 5.99637 10.5489 7.32727 11.1066 7.97341C11.1456 8.02244 11.2143 8.04174 11.273 8.04174C11.361 8.04174 11.4783 8.0124 11.5764 7.92439C11.5764 7.92439 14.0818 5.62466 16.744 5.96703C18.8384 8.25712 17.6152 10.2731 16.0685 11.2617C15.6899 11.5041 15.3344 11.7218 15.041 11.9958C14.2775 12.7103 13.8275 13.5421 13.6515 13.8945C13.2404 14.6773 13.1034 15.0398 12.3889 15.0591C11.6744 15.0787 11.4494 14.1686 11.3513 13.8945C11.2633 13.6205 10.8426 11.2323 10.6662 10.7236C10.4802 10.2149 10.2941 9.94081 9.74639 10.1755C9.19828 10.4005 8.5035 11.0853 8.5035 11.0853C6.38942 10.4881 5.00951 10.8992 5.00951 10.8992L4.87904 10.6225C4.72464 10.3523 4.27535 9.11826 3.54119 9.8914C2.80704 10.6645 2.2103 12.9743 2.2103 12.9743C1.20209 17.7992 3.54119 21.1072 3.54119 21.1072C7.49529 27.2633 14.7079 26.1181 16.3133 25.4723C17.7619 24.8949 21.1578 23.0746 22.6161 19.1205C22.7728 18.6897 22.9099 18.2396 23.0175 17.7502C23.0565 17.5641 23.0959 17.3781 23.1156 17.1924C24.5542 9.67602 18.6331 6.31906 16.9204 5.51658H16.9301H16.9297Z"
          fill="#E81899"
        />
        <path
          d="M12.4911 14.7347L9.97713 15.489C9.87098 15.521 9.81076 15.6326 9.84241 15.7391C9.87407 15.8452 9.986 15.9055 10.0925 15.8738L12.6065 15.1196C12.7126 15.0875 12.7729 14.976 12.7412 14.8695C12.7092 14.7633 12.5976 14.7031 12.4911 14.7347Z"
          fill="black"
        />
        <path
          d="M12.7536 17.0368L10.1585 16.6439C10.0489 16.6273 9.94663 16.7026 9.93003 16.8122C9.91343 16.9218 9.9887 17.0241 10.0987 17.0407L12.6937 17.4336C12.8033 17.4502 12.9056 17.375 12.9222 17.2653C12.9388 17.1557 12.8636 17.0534 12.7539 17.0368H12.7536Z"
          fill="black"
        />
        <path
          d="M8.20955 13.8257C7.8278 13.8257 7.52441 14.1387 7.52441 14.5108C7.52441 14.8829 7.83745 15.196 8.20955 15.196C8.58164 15.196 8.89468 14.8829 8.89468 14.5108C8.89468 14.1387 8.58164 13.8257 8.20955 13.8257Z"
          fill="black"
        />
        <path
          d="M5.40054 14.5108C5.40054 14.1291 5.0875 13.8257 4.71541 13.8257C4.34331 13.8257 4.03027 14.1387 4.03027 14.5108C4.03027 14.8829 4.34331 15.196 4.71541 15.196C5.0875 15.196 5.40054 14.8829 5.40054 14.5108Z"
          fill="black"
        />
        <path
          d="M8.59096 15.8811C8.47362 15.8128 8.32656 15.8615 8.25824 15.9792C8.00387 16.4292 7.73947 16.635 7.44611 16.5566C7.05472 16.4686 6.72199 15.9498 6.72199 15.8032V15.7935C6.72199 15.7935 6.72122 15.7916 6.72122 15.7904C6.72122 15.7893 6.72122 15.7881 6.72122 15.7866C6.72122 15.6515 6.61199 15.5422 6.47689 15.5422C6.34179 15.5422 6.23256 15.6515 6.23256 15.7866C6.23256 15.7881 6.23256 15.7897 6.23256 15.7908C6.23256 15.7916 6.23256 15.7924 6.23256 15.7931V15.8028C6.22291 15.9398 5.89983 16.4682 5.50844 16.5562C5.22473 16.6346 4.95068 16.4289 4.69631 15.9788C4.62799 15.8514 4.48093 15.8124 4.36359 15.8808C4.24624 15.9491 4.19722 16.0961 4.26554 16.2135C4.64729 16.8986 5.07806 17.065 5.40074 17.065C5.49879 17.065 5.58679 17.0453 5.66515 17.026C5.99787 16.938 6.28158 16.6736 6.47766 16.3899C6.67336 16.6639 6.95706 16.9283 7.28979 17.026C7.35811 17.0457 7.45615 17.065 7.55419 17.065C7.86723 17.065 8.29799 16.8986 8.67974 16.2135H8.68939C8.74806 16.0961 8.70908 15.9491 8.59135 15.8808L8.59096 15.8811Z"
          fill="black"
        />
        <path
          d="M2.78876 16.8442L0.170193 17.2564C0.059414 17.2738 -0.0158543 17.3776 0.00151528 17.4884C0.0188849 17.5992 0.122717 17.6744 0.233496 17.6571L2.85206 17.2448C2.96284 17.2275 3.03811 17.1236 3.02074 17.0128C3.00337 16.9021 2.89954 16.8268 2.78876 16.8442Z"
          fill="#E81899"
        />
        <path
          d="M2.94144 15.6755L0.398143 14.9282C0.290837 14.8966 0.178128 14.9583 0.146477 15.0656C0.114825 15.1729 0.176584 15.2856 0.283889 15.3173L2.82718 16.0646C2.93449 16.0962 3.0472 16.0345 3.07885 15.9272C3.1105 15.8199 3.04874 15.7071 2.94144 15.6755Z"
          fill="#E81899"
        />
      </g>
      <defs>
        <clipPath id="clip0_2569_5438">
          <rect width="116" height="30" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Logo;
