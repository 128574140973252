import { ContextApi } from '@pancakeswap/localization'
import { FooterLinkType } from '../../../components/Footer/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('Ecosystem'),
    items: [
      {
        label: t('Swap'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/Liquidity',
      },
      {
        label: t('Farms'),
        href: '/farms',
      },
      // {
      //   label: t('PAN Staking'),
      //   href: '/man-staking',
      // },
    ],
  },
  // {
  //   label: t("Developers"),
  //   items: [
  //     {
  //       label: t("Github"),
  //       href: "https://github.com/",
  //     },
  //   ],
  // },
  {
    label: t('Support'),
    items: [
      {
        label: t('Documentation'),
        href: 'https://docs.panko.finance',
      },
    ],
  },
  // {
  //   label: t("About"),
  //   items: [
  //     {
  //       label: t("Terms Of Service"),
  //       href: "https://www.google.com/",
  //     },
  //     {
  //       label: t("Blog"),
  //       href: "https://www.google.com/",
  //     },
  //     {
  //       label: t("Brand Assets"),
  //       href: "https://www.google.com/",
  //     },
  //   ],
  // },
]
